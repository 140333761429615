import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { Provider as AlertProvider, positions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import App from "./App";

import { useAuth } from "./shared/hooks/auth-hook";
import { AuthContext } from "./shared/context/auth-context";

import "./index.css";

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

const Index = () => {
  const {
    isLoaded,
    token,
    id,
    name,
    lastname,
    region,
    comuna,
    email,
    emailValid,
    availableStatus,
    workType,
    workingStatus,
    level,
    grade,
    certificates,
    languages,
    smsNotification,
    emailNotification,
    jobs,
    professions,
    login,
    update,
    logout,
  } = useAuth();
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          isLoaded,
          token,
          id,
          name,
          lastname,
          region,
          comuna,
          email,
          emailValid,
          availableStatus,
          workType,
          workingStatus,
          level,
          grade,
          certificates,
          languages,
          smsNotification,
          emailNotification,
          jobs,
          professions,
          login,
          update,
          logout,
        }}
      >
        {isLoaded && <App />}
      </AuthContext.Provider>
    </AlertProvider>
  );
};

ReactDOM.render(<Index />, document.getElementById("root"));

serviceWorkerRegistration.register();
