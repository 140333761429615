import { useCallback, useContext } from "react";

import { useHttpClient } from "./http-hook";
import { AuthContext } from "../context/auth-context";

export const PUSH = "push";
export const UPDATE = "update";
export const DELETE = "delete";

export const useExperienceHandler = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleExperience = useCallback(
    async (type, action, data, id = undefined) => {
      const url = getUrl(type, action, auth.id, id);
      const method = getMethod(action);
      const headers = getHeader(action, auth.token);

      try {
        const response = await sendRequest(url, method, data, headers);
        if (!response) {
          return;
        }
        // actualizo en contexto de usuario
        auth.update(action, response.experience, type);
      } catch (err) {
        console.log(err);
      }
    },
    [auth, sendRequest]
  );

  return { handleExperience, isLoading, error, clearError };
};

const getUrl = (type, action, userId, id) => {
  if (action === PUSH) {
    return `${process.env.REACT_APP_BACKEND_URL}/${type}/user/${userId}/push`;
  } else {
    return `${process.env.REACT_APP_BACKEND_URL}/${type}/user/${userId}/${action}/${id}`;
  }
};

const getMethod = (type) => {
  switch (type) {
    case PUSH:
      return "POST";
    case UPDATE:
      return "PATCH";
    case DELETE:
      return "DELETE";
    default:
      return false;
  }
};

const getHeader = (type, token) => {
  if (type === DELETE) {
    return {
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }
};
