import React from "react";

import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ContainedButton = styled(Button)({
  color: "white",
  backgroundColor: "#D3131C",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    "Rajdhani",
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
  ].join(","),
  fontSize: 14,
  textTransform: "none",
  borderWidth: 3,
  fontWeight: "600",
  padding: "0.4rem 0",
  borderColor: "#D3131C",
  "&:hover": {
    backgroundColor: "#D3131C",
    borderColor: "#D3131C",
  },
  "&:disabled": {
    color: "white",
    borderColor: "#B4B4B4",
    backgroundColor: "#B4B4B4",
  },
});

const OutlinedButton = styled(Button)({
  color: "#D3131C",
  borderColor: "#D3131C",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    "Rajdhani",
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
  ].join(","),
  textTransform: "none",
  borderWidth: 3,
  fontSize: 14,
  fontWeight: "700",
  padding: "0.3rem 0",
  boxSizing: "border-box",
  backgroundColor: "white",
  "&:hover": {
    borderWidth: 3,
    borderColor: "#D3131C",
    color: "#D3131C",
    backgroundColor: "white",
  },
  "&:disabled": {
    color: "#B4B4B4",
    borderWidth: 3,
    backgroundColor: "#F1F1F1",
    borderColor: "#B4B4B4",
  },
});

const OutlinedTransparentButton = styled(Button)({
  color: "#D3131C",
  borderColor: "#D3131C",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    "Rajdhani",
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
  ].join(","),
  textTransform: "none",
  borderWidth: 3,
  fontSize: 14,
  fontWeight: "700",
  padding: "0.3rem 0",
  boxSizing: "border-box",
  backgroundColor: "transparent",
  "&:hover": {
    borderWidth: 3,
    borderColor: "#D3131C",
    color: "#D3131C",
    backgroundColor: "transparent",
  },
  "&:disabled": {
    color: "#B4B4B4",
    borderWidth: 3,
    backgroundColor: "transparent",
    borderColor: "#B4B4B4",
  },
});
const ElevatedButton = styled(Button)({
  color: "white",
  backgroundColor: "#D3131C",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    "Rajdhani",
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
  ].join(","),
  fontSize: 14,
  textTransform: "none",
  borderWidth: 63,
  fontWeight: "700",
  padding: "0.6rem 0",
  borderColor: "#D3131C",
  boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.55)",
  "&:hover": {
    backgroundColor: "#D3131C",
    borderColor: "#D3131C",
  },
  "&:disabled": {
    color: "white",
    borderColor: "#B4B4B4",
    backgroundColor: "#B4B4B4",
  },
});
const OutlinedElevatedButton = styled(Button)({
  color: "#D3131C",
  borderColor: "#D3131C",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    "Rajdhani",
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
  ].join(","),
  textTransform: "none",
  borderWidth: 3,
  fontSize: 14,
  fontWeight: "600",
  padding: "0.6rem 0",
  boxSizing: "border-box",
  backgroundColor: "white",
  boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.55)",
  "&:hover": {
    borderWidth: 3,
    borderColor: "#D3131C",
    color: "#D3131C",
    backgroundColor: "white",
  },
  "&:disabled": {
    color: "#B4B4B4",
    borderWidth: 3,
    backgroundColor: "#F1F1F1",
    borderColor: "#B4B4B4",
  },
});

export default function CustomButton(props) {
  if (props.contained) {
    return (
      <ContainedButton
        fullWidth
        disabled={props.disabled}
        type={props.type}
        onClick={props.type === "submit" ? null : props.onClick}
        color="primary"
        href={props.href}
        download={props.download}
        component={props.to && Link}
        to={props.to}
      >
        {props.children}
      </ContainedButton>
    );
  } else if (props.outlined) {
    return (
      <OutlinedButton
        fullWidth
        disabled={props.disabled}
        type={props.type}
        onClick={props.type === "submit" ? null : props.onClick}
        variant="outlined"
        color="primary"
        href={props.href}
        download={props.download}
        disableElevation={props.elevated}
        component={props.to && Link}
        to={props.to}
      >
        {props.children}
      </OutlinedButton>
    );
  } else if (props.transparent) {
    return (
      <OutlinedTransparentButton
        fullWidth
        disabled={props.disabled}
        type={props.type}
        onClick={props.type === "submit" ? null : props.onClick}
        variant="outlined"
        color="primary"
        href={props.href}
        download={props.download}
        disableElevation={props.elevated}
        component={props.to && Link}
        to={props.to}
      >
        {props.children}
      </OutlinedTransparentButton>
    );
  } else if (props.elevated) {
    return (
      <ElevatedButton
        fullWidth
        disabled={props.disabled}
        type={props.type}
        onClick={props.type === "submit" ? null : props.onClick}
        variant="contained"
        color="primary"
        href={props.href}
        component={props.to && Link}
        to={props.to}
      >
        {props.children}
      </ElevatedButton>
    );
  } else if (props.outlinedElevated) {
    return (
      <OutlinedElevatedButton
        fullWidth
        disabled={props.disabled}
        type={props.type}
        onClick={props.type === "submit" ? null : props.onClick}
        variant="contained"
        color="primary"
        href={props.href}
        component={props.to && Link}
        to={props.to}
      >
        {props.children}
      </OutlinedElevatedButton>
    );
  }
}