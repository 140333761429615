import React from "react";

import LandingHeader from "../components/landingHeader";
import LandingBody from "../components/landingBody";
import LandingFooter from "../components/landingFooter";

import "./lading.css";

const LadingPage = () => {
  return (
    <React.Fragment>
      <LandingHeader />
      <LandingBody />
      <LandingFooter />
    </React.Fragment>
  );
};

export default LadingPage;
