import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from './Backdrop';

import './LoadingSpinner.css';

const LoadingSpinner = (props) => {
	if (props.contained) {
		return (
			<div className="spinner_contained">
				<CircularProgress
					variant="indeterminate"
					color="primary"
					style={{ color: '#D31313' }}
				/>
			</div>
		);
	}
	return (
		<Backdrop onClick={null}>
			<div className="center" style={{ height: '100%' }}>
				<CircularProgress
					variant="indeterminate"
					color="primary"
					style={{ color: '#D31313' }}
				/>
			</div>
		</Backdrop>
	);
};
export default LoadingSpinner;
