import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  isLoaded: false,
  login: () => {},
  logout: () => {},
  changedPassword: () => {},
  update: () => {},
  token: null,
  id: null,
  name: null,
  lastname: null,
  region: null,
  comuna: null,
  email: null,
  emailValid: null,
  availableStatus: null,
  workType: null,
  workingStatus: null,
  level: null,
  grade: null,
  certificates: null,
  languages: null,
  smsNotification: null,
  emailNotification: null,
  jobs: null,
  professions: null,
});
