import React from 'react';

import ImageCard from './imageCard';
import LogoMarquee from './marquee';

import ConstruccionImage from '../../assets/images/categories/construccion.webp';
import HosteleriaImage from '../../assets/images/categories/hosteleria.webp';
import ProduccionImage from '../../assets/images/categories/produccion.webp';
import ServicioImage from '../../assets/images/categories/servicios.webp';
import AgriculturaImage from '../../assets/images/categories/agricultura.webp';
import TransporteImage from '../../assets/images/categories/transporte.webp';
import DiseñoImage from '../../assets/images/categories/diseño.webp';
import EducacionImage from '../../assets/images/categories/educacion.webp';

import './landingBody.css';

const LandingBody = () => {
	const categories = [
		{ title: 'Construcción', image: ConstruccionImage },
		{ title: 'Hostelería', image: HosteleriaImage },
		{ title: 'Producción', image: ProduccionImage },
		{ title: 'Servicio', image: ServicioImage },
		{ title: 'Agricultura', image: AgriculturaImage },
		{ title: 'Transporte', image: TransporteImage },
		{ title: 'Diseño', image: DiseñoImage },
		{ title: 'Educación', image: EducacionImage },
	];
	return (
		<main>
			<section className="landing__body-logotipos">
				<LogoMarquee />
			</section>
			<section className="landing__body-categories">
				{categories.map((category) => (
					<ImageCard
						key={category.title}
						title={category.title}
						image={category.image}
					/>
				))}
			</section>
		</main>
	);
};

export default LandingBody;
