import { useCallback, useContext } from "react";

import { useHttpClient } from "./http-hook";
import { AuthContext } from "../context/auth-context";

export const UPDATE_NAME = "name";
export const UPDATE_LOCATION = "location";
export const UPDATE_AVAILABLE = "available";
export const UPDATE_WORKTYPE = "workType";
export const UPDATE_WORKSTATUS = "workStatus";
export const UPDATE_EMAIL = "email";
export const UPDATE_NOTIFICATION = "notification";
export const UPDATE_ACADEMIC = "academic";
export const UPDATE_CERTIFICATES = "certificates";
export const UPDATE_LANGUAGES = "languages";

export const useUpdate = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const updateUser = useCallback(
    async (type, data) => {
      try {
        await sendRequest(getUrl(type), "PATCH", data, {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        });
        // actualizo el contexto
        auth.update(type, data);
      } catch (err) {}
    },
    [auth, sendRequest]
  );

  return { updateUser, isLoading, error, clearError };
};

const getUrl = (type) => {
  switch (type) {
    case UPDATE_NAME:
      return `${process.env.REACT_APP_BACKEND_URL}/newUser/update/name`;
    case UPDATE_LOCATION:
      return `${process.env.REACT_APP_BACKEND_URL}/newUser/update/location`;
    case UPDATE_AVAILABLE:
      return `${process.env.REACT_APP_BACKEND_URL}/newUser/update/available`;
    case UPDATE_WORKTYPE:
      return `${process.env.REACT_APP_BACKEND_URL}/newUser/update/workType`;
    case UPDATE_WORKSTATUS:
      return `${process.env.REACT_APP_BACKEND_URL}/newUser/update/workStatus`;
    case UPDATE_EMAIL:
      return `${process.env.REACT_APP_BACKEND_URL}/newUser/update/email`;
    case UPDATE_NOTIFICATION:
      return `${process.env.REACT_APP_BACKEND_URL}/newUser/update/notification`;
    case UPDATE_ACADEMIC:
      return `${process.env.REACT_APP_BACKEND_URL}/newUser/update/academic`;
    case UPDATE_CERTIFICATES:
      return `${process.env.REACT_APP_BACKEND_URL}/newUser/update/certificates`;
    case UPDATE_LANGUAGES:
      return `${process.env.REACT_APP_BACKEND_URL}/newUser/update/languages`;
    default:
      return "";
  }
};
