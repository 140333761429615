import React from "react";
import { Link } from "react-router-dom";

import "./landingFooter.css";

const LandingFooter = () => {
  return (
    <footer>
      <section className="landing__footer">
        <hr />
        <div className="landing__footer-text-cnt">
          <div className="landing__footer-text">
            <p>Copyright © HUMANTOUCH. Todos los derechos reservados.</p>
          </div>
          <div className="landing__footer-link-container">
            <Link to="/privacy">Privacidad</Link>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default LandingFooter;
