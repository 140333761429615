import React from 'react';

import Marquee from 'react-fast-marquee';

import './marquee.css';

// import de logotipos

const logotipos = [
	// { name: 'testing', src: Logotipo }
];

const LogoContainer = (props) => (
	<div className="logo-container">
		<img src={props.src} alt={props.name} />
	</div>
);

const LogoMarquee = () => {
	return (
		<Marquee>
			{logotipos.length > 0 &&
				logotipos.map((logotipo) => (
					<LogoContainer
						key={logotipo.name}
						name={logotipo.name}
						src={logotipo.src}
					/>
				))}
		</Marquee>
	);
};

export default LogoMarquee;
