import React from 'react';

import './imageCard.css';

const ImageCard = (props) => {
	return (
		<div className="image-card">
			<div className="image-card__title">
				<h2>{props.title}</h2>
			</div>
			<div className="image-card__picture">
				<img
					loading="lazy"
					src={props.image}
					alt={props.title}
					title={props.title}
				/>
			</div>
		</div>
	);
};

export default ImageCard;
