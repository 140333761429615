import React from 'react';
import Button from '../../shared/components/FormElements/button';

import SmallLogo from '../../assets/images/logo-svg.svg';

import './navigation.css';

const LandingNavBar = () => {
	return (
		<div className="landing__nav-container">
			<div className="landing__nav-logo">
				<img src={SmallLogo} alt="HUMANTOUCH logo" title="HUMANTOUCH logo" />
			</div>
			<nav className="landing__nav">
				<Button transparent href="/login">
					Iniciar sesión
				</Button>
			</nav>
		</div>
	);
};

export default LandingNavBar;
