import React from 'react';

import Background from './background';
import Navigation from './navigation';
import Button from '../../shared/components/FormElements/button';

import './landingHeader.css';
import LandingForm from './landingForm';

const LadingHeader = () => {
	return (
		<header className="landing">
			<div className="landing__container">
				<Background />
				<Navigation />
				<div className="landing__header">
					<h1>¡Estamos reclutando!</h1>
				</div>
				<LandingForm />
				<div className="landing__calltoaction">
					<Button contained href="/offer">
						Ver ofertas
					</Button>
				</div>
			</div>
		</header>
	);
};

export default LadingHeader;
