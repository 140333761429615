import { useState, useCallback, useEffect } from "react";

import { useHttpClient } from "./http-hook";

export const useOptions = () => {
  const [fetchedProfessions, setProfessions] = useState(false);
  const [fetchedJobs, setJobs] = useState(false);
  // eslint-disable-next-line
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const getProfessions = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/profession`
      );
      setProfessions(response.professions);
    } catch (err) {
      setProfessions([
        { name: "Por favor, recargar", _id: "profession_not_found" },
      ]);
    }
  }, [sendRequest]);

  const getJobs = useCallback(async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/job`
      );
      setJobs(response.jobs);
    } catch (err) {
      setJobs([{ name: "Por favor, recargar", _id: "job_not_found" }]);
    }
  }, [sendRequest]);

  const updateOptions = () => {
    getProfessions();
    getJobs();
  };

  useEffect(() => {
    getProfessions();
    getJobs();
  }, [getProfessions, getJobs]);

  return { fetchedProfessions, fetchedJobs, updateOptions };
};
