import React, { useEffect, useState, useContext } from "react";

import { stringify } from "query-string";
import { useHistory } from "react-router-dom";

import { useForm } from "../../shared/hooks/form-hook";
import { OptionsContext } from "../../shared/context/options-context";
import { AuthContext } from "../../shared/context/auth-context";
import Button from "../../shared/components/FormElements/button";
import InputSelect from "../../shared/components/FormElements/inputSelect";

import "./landingForm.css";

const LandingForm = () => {
  const [professionAndJobs, setprofessionsAndJobs] = useState(false);

  const options = useContext(OptionsContext);
  const auth = useContext(AuthContext);
  const { professions, jobs } = options;

  const history = useHistory();

  const inputs = {
    job: {
      value: "",
      isValid: false,
    },
  };
  const [formState, inputHandler] = useForm(inputs, false);

  useEffect(() => {
    if (professions && jobs) {
      setprofessionsAndJobs([...professions, ...jobs]);
    }
  }, [professions, jobs]);

  const filterHandler = (event) => {
    event.preventDefault();
    const queryParams = {};
    if (formState.inputs.job.value !== "") {
      const job = jobs.find((job) => job.name === formState.inputs.job.value);
      if (job) {
        queryParams["job"] = job._id;
      } else {
        const profession = professions.find(
          (job) => job.name === formState.inputs.job.value
        );
        queryParams["profession"] = profession._id;
      }
    }

    const queryString = stringify(queryParams);
    history.push("/offer?" + queryString);
  };

  const loginHandler = () => {
    history.push("/login");
  };

  return (
    <div className="landing-form">
      <form className="landing-form__form">
        <InputSelect
          id="job"
          label="Oficio/profesión:"
          options={professionAndJobs}
          isLoaded={professionAndJobs}
          onInput={inputHandler}
          validators={[]}
          initialValidity={true}
          placeholder="Busca por oficio o profesión..."
        />
        <Button contained onClick={filterHandler}>
          Buscar trabajo
        </Button>
      </form>
      {!auth.isLoggedIn && (
        <>
          <hr />
          <div className="landing-form__login">
            <Button outlined onClick={loginHandler}>
              Iniciar sesión
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default LandingForm;
