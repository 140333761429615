import { useState, useEffect, useCallback } from "react";

import {
  UPDATE_NAME,
  UPDATE_LOCATION,
  UPDATE_AVAILABLE,
  UPDATE_WORKTYPE,
  UPDATE_WORKSTATUS,
  UPDATE_EMAIL,
  UPDATE_NOTIFICATION,
  UPDATE_ACADEMIC,
  UPDATE_CERTIFICATES,
  UPDATE_LANGUAGES,
} from "../hooks/update-hook";

import { PUSH, UPDATE, DELETE } from "../hooks/experience-hook";
import { formatAvailableText } from "../utils/format";

export const useAuth = () => {
  const [isLoaded, setIsloaded] = useState(false);
  const [token, setToken] = useState(false);
  const [id, setId] = useState(false);
  const [name, setName] = useState(false);
  const [lastname, setLastname] = useState(undefined);
  const [region, setRegion] = useState(undefined);
  const [comuna, setComuna] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [emailValid, setEmailValid] = useState(undefined);
  const [availableStatus, setAvailableStatus] = useState(undefined);
  const [workType, setWorkType] = useState(undefined);
  const [workingStatus, setWorkingStatus] = useState(undefined);
  const [level, setLevel] = useState(undefined);
  const [grade, setGrade] = useState(undefined);
  const [certificates, setCertificates] = useState(undefined);
  const [languages, setLanguages] = useState(undefined);
  const [smsNotification, setSmsNotification] = useState(undefined);
  const [emailNotification, setEmailNotification] = useState(undefined);
  const [jobs, setJobs] = useState([]);
  const [professions, setProfessions] = useState([]);

  const login = useCallback((data) => {
    setToken(data.token);
    setId(data.id);
    setName(data.name);
    setLastname(data.lastname);
    setRegion(data.region);
    setComuna(data.comuna);
    setEmail(data.email);
    setEmailValid(data.emailValid);
    setAvailableStatus(data.isAvailable);
    setWorkType(data.workType);
    setWorkingStatus(data.workingStatus);
    setLevel(data.level);
    setGrade(data.grade);
    setCertificates(data.certificates);
    setLanguages(data.languages);
    setSmsNotification(data.smsNotification);
    setEmailNotification(data.emailNotification);
    setJobs(data.jobs);
    setProfessions(data.professions);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        token: data.token,
        id: data.id,
        name: data.name,
        lastname: data.lastname,
        region: data.region,
        comuna: data.comuna,
        email: data.email,
        emailValid: data.emailValid,
        isAvailable: data.isAvailable,
        workType: data.workType,
        workingStatus: data.workingStatus,
        level: data.level,
        grade: data.grade,
        certificates: data.certificates,
        languages: data.languages,
        smsNotification: data.smsNotification,
        emailNotification: data.emailNotification,
        jobs: data.jobs,
        professions: data.professions,
      })
    );
  }, []);

  const update = (action, data, type = undefined) => {
    const newData = {
      token: token,
      id: id,
      name: name,
      lastname: lastname,
      region: region,
      comuna: comuna,
      email: email,
      emailValid: emailValid,
      availableStatus: availableStatus,
      workType: workType,
      workingStatus: workingStatus,
      level: level,
      grade: grade,
      certificates: certificates,
      languages: languages,
      smsNotification: smsNotification,
      emailNotification: emailNotification,
      jobs: jobs,
      professions: professions,
    };
    let newProfessions = [];
    let newJobs = [];
    switch (action) {
      case UPDATE_NAME:
        setName(data.name);
        setLastname(data.lastname);
        break;
      case UPDATE_LOCATION:
        setRegion(data.region);
        setComuna(data.comuna);
        break;
      case UPDATE_AVAILABLE:
        // traer el valor de la lista
        const status = formatAvailableText(data.availableStatus);
        setAvailableStatus(status);
        break;
      case UPDATE_WORKTYPE:
        setWorkType(data.types);
        break;
      case UPDATE_WORKSTATUS:
        setWorkingStatus(data.workStatus);
        break;
      case UPDATE_EMAIL:
        setEmail(data.email);
        setEmailValid(false);
        break;
      case UPDATE_NOTIFICATION:
        setSmsNotification(data.sms);
        setEmailNotification(data.email);
        break;
      case UPDATE_ACADEMIC:
        setGrade(data.grade);
        setLevel(data.level);
        break;
      case UPDATE_CERTIFICATES:
        setCertificates(data.certificates);
        break;
      case UPDATE_LANGUAGES:
        setLanguages(data.languages);
        break;
      case PUSH:
        // puede ser job o profession
        if (type === "job") {
          // copiando la data del arreglo original
          newJobs.push(...[...jobs, data]);
          //Necesito ingresar la data al arreglo
          setJobs(newJobs);
        } else {
          // copiando la data del arreglo original
          newProfessions.push(...[...professions, data]);
          //Necesito ingresar la data al arreglo
          setProfessions(newProfessions);
        }
        break;
      case UPDATE:
        // puede ser job o profession
        if (type === "job") {
          // copiando la data del arreglo original
          newJobs.push(...jobs);
          // buscando elemento para actualizar
          const itemIndex = newJobs.findIndex((job) => job.id === data.id);
          if (itemIndex === -1) {
            return;
          }
          // sustituir el elemento por el nuevo
          newJobs[itemIndex] = data;
          // inserto la nueva data en el estado
          setJobs(newJobs);
        } else {
          // copiando la data del arreglo original
          newProfessions.push(...professions);
          // buscando elemento para actualizar
          const itemIndex = newProfessions.findIndex(
            (profession) => profession.id === data.id
          );
          if (itemIndex === -1) {
            return;
          }
          // sustituir el elemento por el nuevo
          newProfessions[itemIndex] = data;
          // inserto la nueva data en el estado
          setProfessions(newProfessions);
        }
        break;
      case DELETE:
        // puede ser job o profession
        if (type === "job") {
          // copiando la data del arreglo original
          newJobs.push(...jobs);
          // buscando elemento para actualizar
          newJobs = newJobs.filter((job) => job.id !== data.id);
          // inserto la nueva data en el estado
          setJobs(newJobs);
        } else {
          // copiando la data del arreglo original
          newProfessions.push(...professions);
          // buscando elemento para actualizar
          newProfessions = newProfessions.filter(
            (profession) => profession.id !== data.id
          );
          // inserto la nueva data en el estado
          setProfessions(newProfessions);
        }
        break;
      default:
        return "";
    }
    if (action !== PUSH && action !== UPDATE && action !== DELETE) {
      localStorage.setItem("userData", JSON.stringify({ ...newData, ...data }));
    } else {
      // tengo que tener el nuevo arreglo de profesiones y jobs
      if (type === "job") {
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...newData, jobs: newJobs })
        );
      } else {
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...newData, professions: newProfessions })
        );
      }
    }
  };

  const logout = useCallback(() => {
    localStorage.removeItem("userData");
    setToken(undefined);
    setId(undefined);
    setName(undefined);
    setLastname(undefined);
    setRegion(undefined);
    setComuna(undefined);
    setEmail(undefined);
    setEmailValid(undefined);
    setAvailableStatus(undefined);
    setWorkType(undefined);
    setWorkingStatus(undefined);
    setLevel(undefined);
    setGrade(undefined);
    setCertificates(undefined);
    setLanguages(undefined);
    setSmsNotification(undefined);
    setEmailNotification(undefined);
    setJobs(undefined);
    setProfessions(undefined);
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.hasOwnProperty("token") &&
      storedData.hasOwnProperty("id") &&
      storedData.hasOwnProperty("name") &&
      storedData.hasOwnProperty("lastname") &&
      storedData.hasOwnProperty("region") &&
      storedData.hasOwnProperty("comuna") &&
      storedData.hasOwnProperty("email") &&
      storedData.hasOwnProperty("emailValid") &&
      storedData.hasOwnProperty("isAvailable") &&
      storedData.hasOwnProperty("workType") &&
      storedData.hasOwnProperty("workingStatus") &&
      storedData.hasOwnProperty("level") &&
      storedData.hasOwnProperty("grade") &&
      storedData.hasOwnProperty("certificates") &&
      storedData.hasOwnProperty("languages") &&
      storedData.hasOwnProperty("smsNotification") &&
      storedData.hasOwnProperty("emailNotification") &&
      storedData.hasOwnProperty("jobs") &&
      storedData.hasOwnProperty("professions")
    ) {
      login(storedData);
    }
    setIsloaded(true);
  }, [login]);

  return {
    isLoaded,
    token,
    id,
    name,
    lastname,
    region,
    comuna,
    email,
    emailValid,
    availableStatus,
    workType,
    workingStatus,
    level,
    grade,
    certificates,
    languages,
    smsNotification,
    emailNotification,
    jobs,
    professions,
    login,
    update,
    logout,
  };
};
