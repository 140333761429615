import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useOptions } from "./shared/hooks/options-hook";
import { OptionsContext } from "./shared/context/options-context";

const theme = createTheme({
    palette: {
        primary: {
            main: "#1A1C2C",
        },
    },
});

const Provider = (props) => {
    const { fetchedProfessions, fetchedJobs, updateOptions } = useOptions();
    return (
        <ThemeProvider theme={theme}>
            <OptionsContext.Provider
                value={{
                    jobs: fetchedJobs,
                    professions: fetchedProfessions,
                    update: updateOptions,
                }}
            >
                {props.children}
            </OptionsContext.Provider>
        </ThemeProvider>
    );
}

export default Provider;