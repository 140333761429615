import React, { Suspense, useContext } from "react";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { AuthContext } from "./shared/context/auth-context";

// componentes helper
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";
import Provider from "./Provider";

// rutas sin autenticacion
import LadingPage from "./Landing/pages/lading";

import "./index.css";

// lazy load sin autenticacion
const LoginPage = React.lazy(() => import("./Auth/Login/pages/login"));
const SingupPage = React.lazy(() => import("./Auth/Signup/pages/signup"));
const LostPasswordPage = React.lazy(() =>
  import("./Auth/LostPassword/pages/lostPassword")
);
const ChangePasswordPage = React.lazy(() =>
  import("./Auth/ChangePassword/pages/changePassword")
);
const OtpPage = React.lazy(() => import("./Auth/OTP/pages/otp"));

const OfferPage = React.lazy(() => import("./Offer/pages/offer"));
const OfferDetails = React.lazy(() => import("./Offer/pages/offerDetails"));

const ProfilePage = React.lazy(() => import("./Profile/pages/profile"));
const PrivacyPage = React.lazy(() => import("./Static/Privacy/privacy"));

const App = () => {
  const { isLoaded, token } = useContext(AuthContext);
  let routes = (
    <Switch>
      <Route path="/privacy" component={PrivacyPage} />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/offer/:id" component={OfferDetails} />
      <Route path="/offer" component={OfferPage} />
      <Redirect to="/offer" />
    </Switch>
  );
  if (!token) {
    routes = (
      <Switch>
        <Route path="/landing" component={LadingPage} />
        <Route path="/privacy" component={PrivacyPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/signup" component={SingupPage} />
        <Route path="/lost-password" component={LostPasswordPage} />
        <Route path="/otp-password" component={OtpPage} />
        <Route path="/password-change" component={ChangePasswordPage} />
        <Route path="/offer/:id" component={OfferDetails} />
        <Route path="/offer" component={OfferPage} />
        <Redirect to="/landing" />
      </Switch>
    );
  }

  return (
    <Provider>
      {isLoaded && (
        <Router>
          <Suspense
            fallback={
              <div className="loadingSpinner--center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </Router>
      )}
    </Provider>
  );
};

export default App;
