import React from 'react';

import SmallBg from '../../assets/images/background/small-bg.webp';
import MediumBg from '../../assets/images/background/medium-bg.webp';

import './background.css';

const ResponsiveBackground = () => {
	return (
		<React.Fragment>
			<div className="responsive__background-filter" />
			<picture className="responsive__background">
				<source type="image/webp" srcSet={`${SmallBg} 750w, ${MediumBg} 2880w`} />
				<img
					src={SmallBg}
					srcSet={`${SmallBg} 750w, ${MediumBg} 2880w`}
					alt="handshake"
					sizes="100vw"
					loading="lazy"
				/>
			</picture>
		</React.Fragment>
	);
};

export default ResponsiveBackground;
