export const formatAvailableText = (text) => {
  switch (text) {
    case "inmediata":
      return "Disponible para incorporación inmediata";
    case "no inmediata":
      return "Disponible, pero no para incorporación inmediata";
    case "no disponible":
      return "No estoy buscando empleo";
    default:
      return "Sin definir";
  }
};
